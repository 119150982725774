import './ProductsCustomers.css';

export const ProductsCustomer = props =>{
    return(
        <div className='productsCustomerContainer'>
            <img alt='' src={'./assets/img/istikbalOrange.svg'}></img>
            <img alt='' src={'./assets/img/mondiOrange.svg'}></img>
            <img alt='' src={'./assets/img/bellonaOrange2.svg'}></img>
        </div>
    );
}